import React, { useState, useContext, Fragment } from "react";
import { AuthContext } from "../components/AuthProvider";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Logo from "../components/Logo";
import { classNames } from "../helpers/styles";
import { ChevronDownIcon, XIcon, MenuIcon } from '@heroicons/react/solid'
import {  Dialog, Transition, Popover } from '@headlessui/react'

import Footer from "./Footer";
import Button from "./Atoms/Button";

const Navbar = ({ navigation, stacked,setMenuSlideOverOpen }) => {
  

  return (
    <nav aria-label="Global">
      {navigation.map((item) => { 
        if(item.disabled) {
          return null
        } 
        return (
        <Link
          onClick={(e)=>{stacked ? setMenuSlideOverOpen(false) : console.log("")}}
          key={item.href}
          to={item.href}
          className={classNames(
            item.current 
              ? "text-brand-500 border-b-2 border-brand-500"
              : (!stacked ? " hover:bg-gray-50  border-b-2 border-transparent hover:border-gray-200" : "text-gray-800 hover:text-gray-900"),

              item.current && stacked ? "border-none text-brand-500" : "",
              " items-center text-base font-medium focus:outline-none",
              stacked ? "block py-4 px-2 text-lg focus:ring-2 focus:ring-offset-2 focus:ring-brand-500" : "py-4 px-6 inline-flex focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              
          )}
          aria-current={item.current ? "page" : undefined}
        >
          {item.name}
        </Link>
      )})}
    </nav>
  );
};

function AuthLayout({ children, bg, location }) {

  const { user, logout, registerInfoForLoggedInUser } = useContext(AuthContext);

  const [menuSlideOverOpen, setMenuSlideOverOpen] = useState(false)
 

  let navigation = [
    { name: "Home", href: "/account/dashboard/", current: location && location.pathname.includes('dashboard'), disabled: false},
    { name: "Classes", href: "/account/classes", current:  location && location.pathname.includes('classes'), disabled: registerInfoForLoggedInUser ? false : true, },
    { name: "Ask the Experts", href: "/account/ask-the-experts", current: location && location.pathname.includes('ask-the-experts'), disabled: registerInfoForLoggedInUser ? false : true },
    { name: "Event Help Desk", href: "/account/help-desk", current: location && location.pathname.includes('help-desk'), secondary: true,  disabled: registerInfoForLoggedInUser ? false : true,  },
  ];

  if(location && location.pathname.includes('update-register-details')){
    navigation= [{ name: "Home", href: "/account/update-register-details/", current: true},]
  }
   if(user?.firstName) {
     return (
     <>
     <Transition.Root show={menuSlideOverOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setMenuSlideOverOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                  <div></div>
 
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="p-3 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:bg-gray-50 hover:bg-gray-50 focus:ring-offset-2 focus:border-black disabled:text-brand-300 focus:ring-2 focus:ring-brand-500"
                          onClick={() => setMenuSlideOverOpen(false)}
                        >
                          
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                          <span className="block uppercase font-semibold" style={{fontSize:'.5rem'}}>Close</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                  <div>
                    <h4 className="font-bold text-xl  py-3 px-2">Navigate</h4>
              <Navbar navigation={navigation} stacked={true} setMenuSlideOverOpen={setMenuSlideOverOpen} registerInfoForLoggedInUser={registerInfoForLoggedInUser}/>
            </div>
            <div className="mt-8">
            <h4 className="font-bold text-xl  py-3 px-2">My Account</h4>

            <div className="px-2 py-3">

 
  <div>
  <span className="block font-semibold text-base">{`${user.firstName} ${user.lastName}`}</span>
  <span className="block font-medium text-gray-700 text-base text-lowercase">{`${user && user.email && user.email.toLowerCase()}`}</span>
  </div>
  <Button color="secondary" onClick={() => logout()}  className="w-full mt-6" >
               Log Out
             </Button> 

              </div>
            </div>
            <div>  
              </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    
    
    
     <div className={`${bg === "gray" ? "bg-gray-50 min-h-screen" : "bg-white"}`}>
        <a
          className="sr-only sr-only-focusable w-full block bg-brand-500 text-white text-center focus:outline-none m-1 p-2 focus:ring-1 focus:ring-offset-1 focus:ring-brand-50"
          href="#main-content"
        >
          Skip to main content
        </a>
        <div className="bg-white shadow-md">
          <div className="max-w-7xl mx-auto px-4 md:px-6">
            <div className="py-4 md:border-b flex justify-between items-center">
              <div className="w-80">
                <Link
                  to={"/account/dashboard"}
                  className="block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                  <Logo fill="#00659e" includeTitle={true} />
                </Link>
              </div>
  
              <button onClick={()=>setMenuSlideOverOpen(true)}  className="focus:ring-offset-2 focus:border-black disabled:text-brand-300 focus:ring-2 focus:ring-brand-500 md:hidden text-center p-2 rounded-lg text-gray-600 hover:bg-gray-200  focus:text-gray-700  focus:bg-gray-50  focus:outline-none  transition duration-150 ease-in">
      
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
            <span className="block uppercase font-semibold" style={{fontSize:'.5rem'}}>Menu</span>
          </button>



          <Popover>
      {({ open }) => (
        <>
          <Popover.Button  className="inline-flex justify-center items-center w-full rounded-md border border-gray-300 shadow-sm px-2 md:px-4 py-1 md:py-2 bg-white  font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-500">
                  <span className="hidden md:inline-block h-6 w-6 md:h-8 md:w-8 rounded-full overflow-hidden bg-gray-100">
          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span> <span className=" ml-2 block capitalize font-semibold text-sm md:text-base">{`${user.firstName} ${user.lastName}`}</span> <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" /></Popover.Button>

          {/* Use the Transition component. */}
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            {/* Mark this component as `static` */}
            <Popover.Panel static className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-500 focus:outline-none"> 
            
              
               <button
               onClick={() => logout()}
               className={`rounded-md block px-4 py-3 w-full text-left  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-500`}
             >
               Log Out
             </button> 
           

       
        </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>


 
            
  
             
            </div>
            <div className="hidden md:block">
              <Navbar navigation={navigation} />
            </div>
          </div>
        </div>
  
        <main id="main-content">{children}</main>
      </div>
      <Footer/></>
    );
  } else return null
 
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AuthLayout;
